export default class CreateShareholderRequest {
    public companyId: number | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public ssn: string | null = null;

    public idCardFront: number | null = null;

    public idCardBack: number | null = null;

    public personId: number | null = null;
}