

























import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
import FileService from "@/services/FileService";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {AxiosResponse} from "axios";
import {documentAccept} from "@/constants/FileConstants";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component
export default class FileUpload extends Vue {

    @AppModule.Action
    private startLoading!: () => void

    @Prop({default: false})
    private isRequired!:boolean;

    @AppModule.Action
    private stopLoading!: () => void

    @Prop({default: true})
    private enabled!: boolean;

    @Prop()
    private userId!: number;

    @Prop({default: true})
    private multiple!: boolean;

    @Prop({default: "200px"})
    private height!: string;

    @Prop({default: () => ""})
    private generateCustomName!: (() => string) | null;

    private hightlight = false;

    @Prop({default: documentAccept})
    private accept!: string;

    @Prop({default: null})
    private customLabel!: string | null;

    @Prop({default: null})
    private onUploaded!: (files: FileMetaDTO[]) => void


    openFileSelection(): void {
        if (!this.enabled) return;
        (this.$refs.fileInput as HTMLElement).click();
    }

    onDragOver(event: DragEvent): void {
        if (!this.enabled) return;
        event.preventDefault();
        this.hightlight = true;
    }

    onDragLeave(event: DragEvent): void {
        this.hightlight = false;
    }

    onDrop(event: DragEvent): void {
        if (!this.enabled || !event.dataTransfer) return;
        event.preventDefault();
        const files = event.dataTransfer.files;
        let result: File[] = [];
        for (let i = 0; i < files.length; i++) {
            result.push(files.item(i) as File);
        }
        this.hightlight = false;
        this.uploadFiles(result);
    }

    onFileChanged(event: any) {
        let files: FileList = event.target.files;
        let result: File[] = [];
        for (let i = 0; i < files.length; i++) {
            result.push(files.item(i) as File);
        }
        this.uploadFiles(result);
    }

    async uploadFiles(files: File[]){
        this.startLoading();
        let result: FileMetaDTO[] = [];
        const promises: Promise<AxiosResponse<FileMetaDTO>>[] = [];
        files.forEach(f => {
            const customName: string | null = this.generateCustomName ? this.generateCustomName() : null;
            promises.push(FileService.uploadFile(f, this.userId, customName));
        });
        await Promise.all(promises).then(
            response => {
                response.forEach(r => {
                    result.push(r.data);
                });
                this.$emit("filesUploaded", result);
                if(this.onUploaded){
                  this.onUploaded(result);
                }
                this.stopLoading();
            },
            error => {
                this.stopLoading();
            }
        );
    }
}
