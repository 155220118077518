























































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RegexUtils from "@/utils/RegexUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");

@Component({
	components: {PaginationComponent}
})
export default class PersonSearchModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private filter = new PersonFilterDTO();

  private persons: Array<PersonDTO> = [];

  private totalPages = 1;

  private message = "";

  private successful = true;

  @Prop({default: null})
  private title!: string | null;

  @Prop()
  private onPersonSelected!: (person: PersonDTO) => any

  mounted(){
    this.loadPersons();
  }

  loadPersons(){
    this.startLoading();
    this.message = "";
    PersonService.getByFilter(this.filter).then(
        (response) => {
          this.persons = response.data.data;
          this.totalPages = response.data.countOfPages;
          this.successful = true;
          this.stopLoading();
        },
        (error) => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    )
  }

  changePage(page: number){
    this.filter.pageNumber = page;
    this.loadPersons();
  }

  selectAndClose(person: PersonDTO){
    this.onPersonSelected(person);
    this.$modal.hideAll();
  }

    handleName(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
            event.preventDefault();
        }
    }

    get getEmailSortIcon(): string {
        return this.getSortIcon("EMAIL");
    }

    get getFirstNameSortIcon(): string {
        return this.getSortIcon("FIRST_NAME");
    }

    get getLastNameSortIcon(): string {
        return this.getSortIcon("LAST_NAME");
    }

    getSortIcon(field: string) {
        if (this.filter.orderField !== field) {
            return "sort";
        } else if (this.filter.orderDirection === "ASC") {
            return "sort-up";
        } else {
            return "sort-down";
        }
    }

    switchOrder(field: string) {
        if (field === this.filter.orderField) {
            this.toggleDirection();
        } else {
            this.filter.orderField = field;
            this.filter.orderDirection = "ASC";
        }
        this.loadPersons();
    }

    toggleDirection() {
        this.filter.orderDirection = this.filter.orderDirection === "ASC" ? "DESC" : "ASC";
    }

    clearFilter() {
        this.filter.firstName = "";
        this.filter.lastName = "";
        this.loadPersons();
    }

}
